body {
  background-color: white;
}
.logo {
  height: 100px;
}
.header-section.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background-color: #1b1d4d80;
}
.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgba(0, 0, 0, .1);
}
h1, h1 > a {
  font-size: 66px;
  line-height: 65px;
  margin-top: -20px;
}
.cmn-btn {
  color: rgb(255 255 255);
}
.popular-game .section-text .title, .popular-game .section-text p {
  color: #351f58;
}
.popular-game .section-text  .sub-title {
  color: black;
}
.top-area  .sub-title {
  color: black;
}
.why-choose .section-header  .sub-title {
  color: black;
}
.why-choose .section-header .title, .why-choose .section-header p {
  color: #351f58;
}
.why-choose .single-box h5, .why-choose .single-box p {
  color: #351f58;
}
.latest-bets .section-header  .sub-title {
  color: black;
}
.latest-bets .section-header .title, .latest-bets .section-header p {
  color: #351f58;
}
.latest-bets .single-box h5, .latest-bets .single-box p {
  color: #351f58;
}
.why-choose .single-box img {
  height: 200px;
}
.features .section-text .sub-title {
  color: #ff00c8;
}
.banner-section .overlay .banner-content .main-content .top-area h1 span {
  color: #fff;
}
.counter-section .single-box {
  border-radius: 10px;
  background: linear-gradient(45deg, #694cb9, rgb(32, 16, 72))!important;
  padding: 25px 30px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right bottom;
}
.slick-slide img {
  border-radius: 10px;
  width: 250px;
}
.features .single-box {
  padding: 60px 0 50px;
  border-radius: 20px;
  text-align: center;
  background: linear-gradient(359deg, #200028, rgba(112, 36, 160, 0.2)) !important;
}
.header-section .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active, .header-section .navbar .navbar-collapse .navbar-nav .nav-item .dropdown-item.active {
  color: #db00c5 !important;
}
.img_item {
  cursor: pointer;
}
.dropdown-toggle btn btn-default {
  color: #fff !important;
}
.xlr {
  color: #200028 !important;
}
.desc-item {
  min-height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background: #000000d4;
  padding: 5px;
}
.desc-item p{
  font-size: 12px;
}
.product-vegas {
  background-image: url("../images/modals/vegas-m.jpg");
  background-size: 100%;
}
.product-jurassic {
  background-image: url("../images/modals/jurassic-m.png");
  background-size: 100%;
}
.product-caliente {
  background-image: url("../images/modals/fiestacaliente-m.png");
  background-size: 100%;
}
.product-space {
  background-image: url("../images/modals/space-m.png");
  background-size: 100%;
}
.product-galaxy {
  background-image: url("../images/modals/galaxy-m.jpg");
  background-size: 100%;
}
.product-juega {
  background-image: url("../images/modals/juega-m.jpg");
  background-size: 100%;
}
.product-bg {
  background: #21252999;
}
.single-box .icon-box img {
  height: 150px;
}
.img-area img {
  height: 100px;
}
.close {
  background: transparent;
}
.close span {
  color: black !important;
}